<template>
  <div class="panel-margin relative pb-2 _question-panel">
    <div class="__difficulty-text">
      <difficulty-indicator :difficulty="_.get(item, 'data.difficulty', '')"/>
    </div>
    <div class="flex mb-6">
      <div class="bg-grid-color-secondary mr-2" style="width: 100%">
        <ComplexEditor
          :questionnaire_id="parent_id"
          v-bind:editor_data.sync="editorData"
          v-on:blur="onBlur"
          @data-changed="onDataChange"
          v-on:destroy="onDestroy"
          :placeholder="`${$t('digite-a-questao-aqui')}...`"
        ></ComplexEditor>
      </div>
    </div>

    <div class="options" style="padding-bottom: 20px">
      <div v-if="inlineOptions">
        <vs-row v-for="(option, index) in options" :key="option">
          <vs-col vs-type="flex" vs-justify="center" vs-align="left" vs-w="1">
            <vs-button
              type="filled"
              :icon="
                option.selected
                  ? 'radio_button_checked'
                  : 'radio_button_unchecked'
              "
              :class="
                option.selected
                  ? 'footer-button checked-button'
                  : 'footer-button'
              "
              @click="itemSelected(option)"
              v-tooltip.top="{
                content: $t('marcar-opcao-correta'),
                delay: { show: 1000, hide: 100 }
              }"
            ></vs-button>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="10">
            <TitleComponent
              :placeholder="$t('opcao')"
              tag="h5"
              class="ml-2 mt-2 w-full"
              :item="option"
              :update-title="false"
              type="option"
              editor-type="balloon"
              @title-TITLE_UPDATED="updateOptionTitle"
              @title-ENTER_PRESSED="addNew(index)"
            ></TitleComponent>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="1">
            <vs-button
              v-if="!(('complex' in option) && option.complex)"
              type="filled"
              icon="insert_photo"
              class="footer-button"
              @click="showImageUploadModal(option)"
            ></vs-button>
            <vs-button
              type="filled"
              icon="clear"
              class="footer-button"
              @click.native="deleteOption(option)"
              v-tooltip.right="{
                content: $t('action.delete'),
                delay: { show: 1000, hide: 100 }
              }"
            ></vs-button>
          </vs-col>
        </vs-row>
      </div>

      <div v-else>
        <div
          :class="optionsGridClass"
          v-for="opts in chunkedOptions"
          :key="opts"
        >
          <vs-row v-for="(option, index) in opts" :key="option">
            <div v-if="option.label !== 'new_option'" class="w-full">
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="12"
              >
                <div
                  @click="showImageUploadModal(option)"
                  style="
                    min-width: 100px;
                    min-height: 100px;
                    max-width: 50%;
                    max-height: 50%;
                    cursor: pointer;
                  "
                >
                  <img
                    :key="option.key"
                    :src="!isEmpty(option.image) ? option.image.src : require('@/assets/images/picture-placeholder.png')"
                    width="100"
                  />
                </div>
              </vs-col>

              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="12"
              >
                <vs-button
                  type="filled"
                  :icon="
                    option.selected
                      ? 'radio_button_checked'
                      : 'radio_button_unchecked'
                  "
                  :class="
                    option.selected
                      ? 'footer-button checked-button'
                      : 'footer-button'
                  "
                  @click="itemSelected(option)"
                ></vs-button>
                <vs-button
                  type="filled"
                  icon="clear"
                  class="footer-button"
                  style="padding: 8px; marging-top: -10px"
                  @click.native="deleteOption(option)"
                  v-tooltip.right="{
                    content: 'Excluir',
                    delay: { show: 1000, hide: 100 }
                  }"
                ></vs-button>
              </vs-col>

              <vs-col
                class="option_editor"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="12"
              >
                <TitleComponent
                  :placeholder="$t('opcao')"
                  tag="h5"
                  :item="option"
                  :update-title="false"
                  type="option"
                  @title-ENTER_PRESSED="addNew(index)"
                  @title-TITLE_UPDATED="updateOptionTitle"
                ></TitleComponent>
              </vs-col>
            </div>
            <div v-else class="flex w-full">
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="12"
              >
                <vs-button
                  type="filled"
                  icon="add"
                  class="footer-button"
                  style="padding: 8px; marging-top: -10px"
                  @click="addNew()"
                  >{{ $t('adicionar-opcao') }}</vs-button
                >
              </vs-col>
            </div>
          </vs-row>
        </div>
      </div>

      <div class="flex mt-4" v-if="inlineOptions">
        <vs-list>
          <vs-list-item>
            <div class>
              <vs-button
                type="filled"
                icon="add"
                class="footer-button"
                style="padding: 8px; marging-top: -10px"
                @click="addNew()"
                >{{ $t('adicionar-opcao') }}</vs-button
              >
            </div>
          </vs-list-item>
        </vs-list>
      </div>
    </div>

    <Footer :showInvalidateButton="showInvalidateButton" v-if="questionnaireMode" :item="item" :cheetEnabled="cheetEnabled"></Footer>

    <!--FEEDBACK POPUP -->
    <vs-popup
      :title="$t('imagem')"
      :active.sync="showUploadPopup"
      style="overflow-y: auto; z-index: 54000;"
    >
      <vs-tabs :color="colorx" v-model="tabIndex">
        <vs-tab :label="$t('computador')">
          <div class="flex flex-wrap items-center">
            <vue-dropzone
              id="upload"
              ref="fileUploadZone"
              v-on:vdropzone-success="success"
              v-on:vdropzone-sending="sending"
              v-on:vdropzone-error="error"
              v-on:vdropzone-file-added="added"
              v-on:vdropzone-queue-complete="complete"
              :use-font-awesome="true"
              :options="dropzoneOptions"
              :use-custom-dropzone-options="true"
              :max_number_of_files="1"
              :useCustomSlot="true"
              style="width: 100%; margin-bottom: 10px"
            >
              <div class="dropzone-custom-content">
                <h3 class="dropzone-custom-title">
                  {{ $t('arraste-e-solte-para-enviar-a-imagem') }}
                </h3>
                <div class="subtitle">
                  {{ $t('ou-selecione-uma-imagem-do-seu-computador') }}
                </div>
              </div>
            </vue-dropzone>
          </div>
        </vs-tab>
        <vs-tab :label="$t('google')">
          <GoogleSearch :option_id="selectedOptionId" @onImageSelected="googleImageSelected"></GoogleSearch>
        </vs-tab>
      </vs-tabs>
    </vs-popup>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import standard from '@/services/standard'
import MediaService from '@/services/api/MediaService'
import ContentQuestionnaireService from '@/services/api/ContentQuestionnaireService'
import { _ } from 'vue-underscore'
import DifficultyIndicator from '@/components/DifficultyIndicator.vue'

const ComplexEditor = () => import('../ComplexEditor')
const TitleComponent = () => import('./title/TitleComponent')
const Footer = () => import('../Footer')
const GoogleSearch = () => import('../GoogleSearch')

export default {
  components: {
    TitleComponent,
    Footer,
    GoogleSearch,
    vueDropzone: vue2Dropzone,
    ComplexEditor,
    DifficultyIndicator
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    parent_id: {
      type: Number,
      default: null
    },
    mode: {
      type: String,
      default: 'questionnaire'
    },
    showInvalidateButton: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    cheetEnabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      mediaService: null,
      contentQuestionnaireService: null,
      options: [],
      showComplexEditor: false,
      showUploadPopup: false,
      editorData: this.item !== null ? this.item.data.title : '',
      dropzoneOptions: {
        url: this.uploadUrl,
        addRemoveLinks: true,
        headers: { Authorization: `Bearer ${standard.token()}` },
        acceptedFiles: 'image/*'
      },
      defaultOption: {
        id: this.$uuidKey(),
        key: 0,
        label: '',
        value: '',
        selected: false,
        image: null
      },
      selectedOptionId: null,
      optionsGridClass: '',
      chunkedOptions: []
    }
  },
  methods: {
    updateOptionTitle(item, type, value) {
      if (this.item.id === item.id) {
        if (type === 'title') {
          const data = this._.cloneDeep(item.data)
          data.title = value
          this.editorData = value
          this.item.data = data
          this.$parent.$emit('item-ITEM_UPDATED', this.item)
        }
      } else if (type === 'option') {
        const updateOption = this.item.data.options.findIndex((option) => {
          return option.id === item.id
        })

        if (updateOption !== -1) {
          this.item.data.options[updateOption].label = value
        }

        this.$parent.$emit('item-ITEM_UPDATED', this.item)
      }
    },
    addNew(index = null) {
      const option = this._.cloneDeep(this.defaultOption) //deep copy
      option.id = this.$uuidKey()
      option.label = this.$t('opcao-this-options-length-1-1', [this.options.length + 1])

      if (!this.isEmpty(index)) {
        this.options.splice(index + 1, 0, option)
      } else {
        this.options.push(option)
      }

      this.item.data.options = this.options
      this.$parent.$emit('item-ITEM_UPDATED', this.item)
    },
    deleteOption(option) {
      this.options = this.options.filter(element => {
        return element.id !== option.id
      })
      this.item.data.options = this.options
      this.$parent.$emit('item-ITEM_UPDATED', this.item)
    },
    itemSelected(option) {
      this.options.forEach(option => {
        option.selected = false
      })
      this.item.data.options.forEach((option) => {
        option.selected = false
      })

      const itemOption = this.item.data.options.find((_option) => _option.id === option.id)

      option.selected = true
      itemOption.selected = option.selected

      this.$parent.$emit('item-ITEM_UPDATED', this.item)
    },
    onBlur() {
      this.item.data.title = this.editorData
      this.$parent.$emit('item-ITEM_UPDATED', this.item)
    },
    updateItem() {
      this.item.data.title = this.editorData
      this.$parent.$emit('item-ITEM_UPDATED', this.item)
    },
    onDataChange() {
      this.updateItem()
    },
    onDestroy() {

    },
    // Image Upload
    showImageUploadModal(option) {
      this.selectedOptionId = option.id
      this.showUploadPopup = this.selectedOptionId !== null
    },
    setOptionImage(response) {
      if (response.id) {
        const option = this.options.filter(option => {
          return option.id === response.id
        })

        if (option && option.length > 0) {
          option[0].image = {
            src: response.base64
          }
        }

        const itemOption = this.item.data.options.filter(option => {
          return option.id === response.id
        })

        if (itemOption && itemOption.length > 0) {
          itemOption[0].image = {
            src: response.base64
          }
        }

        this.$parent.$emit('item-ITEM_UPDATED', this.item)
      }
      this.selectedOptionId = null
      this.showUploadPopup = false
    },
    /** Dropzone **/
    success(file) {
      // this.$refs.fileUploadZone.processQueue()
      const response = JSON.parse(file.xhr.response)
      this.setOptionImage(response)
    },
    sending(file, xhr, formData) {
      formData.append('option_id', this.selectedOptionId)
    },
    cancel() {
      this.removeAllFiles()
    },
    removeAllFiles() {
      this.$refs.fileUploadZone.removeAllFiles()
    },
    error(file) {
      if (file.status === 'error') {
        this.notifyError(this.$vs, file.xhr.response)
        this.removeAllFiles()
      }
    },
    added(file) {
      file['key'] = this.getFilenameIndex(file.name)
    },
    complete(file, xhr, formData) {
      this.removeAllFiles()
    },
    getFilenameIndex(name) {
      const res = name.replace(' ', '_')
      return res.replace(/[^0-9a-zA-Z]/g, '')
    },
    checkOptionsGridClass() {
      this.optionsGridClass = `grid gap-4 mt-4 grid-cols-${this.chunkQuantity}`
    },
    googleImageSelected(image, option_id) {
      this.$vs.loading()
      this.mediaService
        .optimizeUrl(option_id, image.link)
        .then(response => {
          this.setOptionImage(response)
          this.$vs.loading.close()
        }, error => {
          this.$vs.loading.close()
        })
    },
    chunkOptions() {
      const chunked = _.chunk(this.options, 3)
      if (chunked[chunked.length - 1].length <= 3) {
        chunked[chunked.length - 1].push({ label: 'new_option' })
      } else {
        chunked.push([{ label: 'new_option' }])
      }
      this.chunkedOptions = chunked
    },
    uploadUrl() {
      return `${process.env.VUE_APP_API_BASE_URL}/api/v1/medias/${this.selectedOptionId}/optimize_image`
    }
  },
  watch: {
    windowWidth() {
      this.checkOptionsGridClass()
    },
    options() {
      this.chunkOptions()
    }
  },
  computed: {
    chunkQuantity() {
      return this.windowWidth < 430 ? 1 : this.windowWidth < 600  ? 2 : 3
    },
    inlineOptions() {
      let inline = true
      for (let index = 0; index < this.options.length; index++) {
        const option = this.options[index]
        if (option.image !== null) {
          inline = false
          break
        }
      }
      return inline
    },
    windowWidth() {
      return this.$store.state.windowWidth
    },
    questionnaireMode() {
      return this.mode === 'questionnaire'
    },
    formMode() {
      return this.mode === 'form'
    }
  },
  mounted() {

    this.$emit('questionRendered')

    this.checkOptionsGridClass()
    if (this.item.data.editor_type === 'complex') {
      this.showComplexEditor = true
    }

    if (this.item.type === 'multiple_choice') {
      this.options = this.item.data.options.map((option) => {
        const complex = /<.+>/g.test(option.label)
        return {...option, complex}
      })
    }

    // Inserting first blank option
    if (this.options.length === 0) {
      const option = { ...this.defaultOption }
      option.id = this.$uuidKey()
      option.label = this.$t('opcao-this-options-length-1-2', [this.options.length + 1])
      this.options.push(option)
      this.item.data.options = this.options
    }

    this.$on('footer-DELETE_ITEM', (item) => {
      this.$parent.$emit('item-DELETE_ITEM', item)
    })

    this.$on('footer-INVALIDATE_ITEM', (item) => {
      this.$parent.$emit('item-INVALIDATE_ITEM', item)
    })

    this.$on('footer-REVALIDATE_ITEM', (data) => {
      this.$parent.$emit('item-REVALIDATE_ITEM', data)
    })

    this.$on('footer-DUPLICATE_ITEM', (item) => {
      this.$parent.$emit('item-DUPLICATE_ITEM', item)
    })

    this.$on('footer-ITEM_UPDATED', (item) => {
      this.$parent.$emit('item-ITEM_UPDATED', item)
    })
  },
  beforeMount() {
    this.mediaService = MediaService.build(this.$vs)
    this.contentQuestionnaireService = ContentQuestionnaireService.build(
      this.$vs
    )
  }
}
</script>

<style>
.mt {
  padding-top: 10px;
}

.options .vs-list--item {
  max-height: 20px;
}

.vs-tabs .con-tab .vs-button-filled:hover {
  color: #5f6368 !important;
}

.option_editor > editor__content > .ProseMirror {
  max-width: 200px;
}
.ProseMirror-focused:focus h5 {
  background-color: #f8f8f8 !important;
  border: 1px solid rgba(var(--vs-primary), 1) !important;
  padding: 0.7rem !important;
  border-radius: 5px;
}
</style>
